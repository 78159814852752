<template>
  <div class="page-bulk-exports extra-large-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        @click="$router.replace({ name: 'InfluencerInsight' })"
      >
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>
    </div>

    <div
      v-if="retryData"
      class="px-4 mx-auto mt-8 mb-4 mb-md-0"
      style="max-width: 600px"
    >
      <v-card
        dark
        color="secondary"
      >
        <v-card-title>
          {{ retryData.title || "Your export is ready!" }}
        </v-card-title>

        <v-card-subtitle>
          {{ retryData.text || "It should start downloading itself." }}
        </v-card-subtitle>

        <v-card-text>
          <p v-if="retryData.download && retryData.download.url">
            If it doesn't start downloading, please click the button below.
          </p>

          <template v-if="retryData.counts">
            <div v-if="retryData.counts.pending">There were {{ retryData.counts.pending }} pending profiles.</div>
            <div v-if="retryData.counts.error">{{ retryData.counts.error }} profiles resulted in error.</div>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="retryExport()"
          >
            <v-icon left> refresh </v-icon>

            Retry
          </v-btn>

          <v-btn
            v-if="retryData.download && retryData.download.url"
            text
            class="ml-3"
            action="_blank"
            :href="retryData.download.url"
          >
            <v-icon left> download </v-icon>

            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-card max-width="600" class="mx-auto accent mt-sm-8 mt-md-12 mt-lg-16" flat>
      <div class="d-flex flex-wrap align-center justify-space-between pr-4">
        <div>
          <v-card-title> Bulk Export </v-card-title>

          <v-card-subtitle> Add upto {{ maxItems }} profiles at once. </v-card-subtitle>
        </div>

        <div class="d-flex align-center">
          <!-- Show the button to download example sheet -->
          <v-btn
            v-show="selectedType !== 'zip'"
            text
            large
            depressed
            class="ml-4 ml-sm-0"
            color="buttonPrimary"
            @click="downloadSampleSheet"
          >
            <v-icon left> download </v-icon>

            Sample
          </v-btn>

          <v-btn
            :to="{ name: 'InfluencerInsightsExportHistory' }"
            class="shadow--button-primary ml-3"
            color="buttonPrimary"
            depressed
            large
          >
            History
          </v-btn>
        </div>
      </div>

      <v-card-text>
        <!-- Show the error messages here -->
        <p v-if="items.length > maxItems" class="red--text">Please enter a maximum of {{ constants.model.influencerInsightExport.maximumItems }} profiles at once</p>

        <!-- Show the select inputs -->
        <v-row
          class="mt-3 mt-sm-0"
          no-gutters
        >
          <v-col cols="12" md="6">
            <!-- show the input for searching terms -->
            <div class="search-input">
              <v-select solo flat hide-details v-model="selectedType" :items="typeOptions" placeholder="Export File Type"></v-select>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <!-- the select options for different platforms -->
            <platform-selector
              v-model="selectedPlatforms"
              class="platform-selector"
              allow-multiple
              full-width
            />
          </v-col>
        </v-row>

        <div
          v-for="(platform, index) in selectedPlatforms"
          :key="platform"
          class="mt-4"
        >
          <div v-if="invalidItems[platform]">
            <!-- Show error messages for all invalid profile/links -->
            <p
              v-for="(item, index) in invalidItems[platform]"
              :key="index"
              class="red--text"
            >
              Profile link #{{ items[platform].indexOf(item) + 1 }} ({{ item }}) is not valid
            </p>
          </div>

          <!-- Show the textarea input -->
          <v-textarea
            v-model="itemsInput[platform]"
            :placeholder="`Profile URLs/Usernames\n\n${placeholders[platform]}`"
            :label="`${capitalizeString(platform)} profiles`"
            hide-details
            flat
            solo
          ></v-textarea>

          <!-- If it's not the last -->
          <v-divider
            v-if="index !== selectedPlatforms.length - 1"
            class="mt-4"
          />
        </div>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer></v-spacer>

        <v-btn
          :class="{ 'shadow--primary': !shouldCTABeDisabled }"
          :disabled="shouldCTABeDisabled"
          :loading="isMakingRequest"
          color="primary"
          type="submit"
          large
          depressed
          @click="handleSubmit"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// Import node packages
const XLSX = require("xlsx")

// Import helper functions
import { getClientID } from "@/helpers/clientHelper"
import platformRegex from "@/helpers/platformRegex"
import messageEvents from "@/helpers/messageEvents"
import isURL from "@/helpers/isURL"

// Import child components
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Define the key for handling subscriptions
const subscriptionId = Symbol("InfluencerInsightsExport")

// Define the default items input
const defaultItemsInput = () => ({
  instagram: "",
  youtube: "",
  tiktok: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerInsightsExport",

  // Register children components
  components: {
    PlatformSelector
  },

  // Define local data variables
  data: () => ({
    selectedType: null,
    selectedPlatforms: [],

    // Store the entire links input in this string
    itemsInput: defaultItemsInput(),

    minItems: 2,
    maxItems: constants.model.influencerInsightExport.maximumItems,

    isMakingRequest: false,
    triggerId: null,

    retryData: null,

    placeholders: {
        instagram: `https://www.instagram.com/username1
https://www.instagram.com/username2`,

        tiktok: `https://www.tiktok.com/@username1
https://www.tiktok.com/@username2`,

        youtube: `https://www.youtube.com/@username1
https://www.youtube.com/channel/username2`
      }
  }),

  // Define local readonly data variables
  computed: {
    /**
     * Compute the input string and try to generate an array
     *
     * @returns {Array}
     */
    items() {
      const breakCharacter = "*STRING&ENDS&HERE*"

      // The object to return
      const data = {}

      // Loop through each of the platforms
      for (const platform of Object.keys(this.itemsInput)) {
        // If this platform is not selected
        if (!this.selectedPlatforms.includes(platform)) {
          data[platform] = []
        }

        data[platform] = this.itemsInput[platform]
          .replaceAll(" ", breakCharacter)
          .replaceAll("\n", breakCharacter)
          .split(breakCharacter)
          .map((item) => item.trim())
          .filter((item) => Boolean(item))
      }

      return data
    },

    /**
     * Whether or not the items are all within range
     *
     * @returns {Boolean}
     */
    areItemsOverflowing() {
      return Object.values(this.items).reduce((sum, curr) => sum + curr.length, 0) >= this.maxItems
    },

    /**
     * Whether or not the items are all within range
     *
     * @returns {Boolean}
     */
    areNotEnoughItems() {
      return Object.values(this.items).reduce((sum, curr) => sum + curr.length, 0) < this.minItems
    },

    /**
     * Make sure that all the items are valid string values, otherwise return an array of line numbers which are invalid
     *
     * @returns {Array}
     */
    invalidItems() {
      // The object to return
      const data = {}

      // Loop through each of the platforms
      for (const platform of Object.keys(this.itemsInput)) {
        // If this platform is not selected
        if (!this.selectedPlatforms.includes(platform)) {
          data[platform] = []
        }

        data[platform] = this.items[platform].filter((item) => {
          // Check if the input is kind of an URL
          if (isURL(item)) {
            // Try getting the regex match for it
            const match = item.match(platformRegex[platform])

            // If the match is found, hence found the userID for this link
            if (match !== null && match[1]) {
              return false
            } else {
              return true
            }
          }
          // Fallback to true
          else {
            return false
          }
        })
      }

      return data
    },

    /**
     * Whether or not the submit button be disabled
     *
     * @returns {Boolean}
     */
    shouldCTABeDisabled() {
      // If it's loading
      if (this.isMakingRequest) {
        return true
      }

      // If the minimum condition is not satisfied
      if (this.areNotEnoughItems) {
        return true
      }

      // If there are more items than the maximum
      if (this.areItemsOverflowing) {
        return true
      }

      // If there are invalid items
      if (Object.values(this.invalidItems).reduce((sum, curr) => sum + curr.length, 0) > 0) {
        return true
      }
    },

    /**
     * Select input possible option values for type
     *
     * @returns {Array}
     */
    typeOptions() {
      const items = [
        {
          text: "PDF",
          value: "zip",
          condition: this.constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF
        },

        {
          text: "XLSX",
          value: "sheet",
          condition: this.constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX
        }
      ]

      return items.filter((item) => this.$store.getters["auth/isServiceAllowed"](item.condition))
    },
  },

  // Define local method functions
  methods: {
    /**
     * Handle the form submission to generate export
     *
     * @returns {void}
     */
    async handleSubmit() {
      // If some request is in queue
      if (this.isMakingRequest) {
        this.$store.dispatch("toasts/add", {
          text: "Please wait ..."
        })

        return false
      }

      // If there's none selected platform
      if (this.selectedPlatforms.length === 0) {
        this.$store.dispatch("toasts/add", {
          text: "Please select a platform"
        })

        return false
      }

      // If the there's not at-least minimum items
      if (this.areNotEnoughItems) {
        this.$store.dispatch("toasts/add", {
          text: `Please add at least ${this.minItems} profiles`
        })

        return false
      }

      // If there are more than the maximum items
      if (this.areItemsOverflowing) {
        this.$store.dispatch("toasts/add", {
          text: `Please add at most ${this.maxItems} profiles`
        })

        return false
      }

      // If the file export type is not selected
      if (this.selectedType === null) {
        this.$store.dispatch("toasts/add", {
          text: "Please select an export file type"
        })

        return false
      }

      // Put a loader
      this.isMakingRequest = true
      this.triggerId = String(Date.now())

      // Hide the previous dialog
      this.retryData = null

      try {
        // The object to send
        const accounts = {}

        // Loop through selected platforms
        for (const platform of this.selectedPlatforms) {
          // Get the values
          const values = this.items[platform].map((item) => {
            // Check if the input is kind of an URL
            if (isURL(item)) {
              // Try getting the regex match for it
              const match = item.match(platformRegex[platform])

              // If the match is found, hence found the userID for this link
              if (match !== null && match[1]) {
                return match[1]
              } else {
                return item
              }
            }
            // Fallback to true
            else {
              return item
            }
          })

          // Add unique values to the object
          accounts[platform] = Array.from(new Set(values))
        }

        // Make the network request
        await axios({
          url: "/api/influencer-insight-exports/",
          method: "POST",
          data: {
            type: this.selectedType,
            // Pass the current tab's ID to make sure only we download the file once
            clientId: getClientID(),
            triggerId: this.triggerId,
            includePreviousReports: true,
            accounts
          }
        })

        // Show an alert message
        this.$store.dispatch("toasts/add", {
          text: "Your export is in queue, it might take some minutes"
        })

        // Reset the form
        this.itemsInput = defaultItemsInput()
      } catch (error) {
        logger({ type: "Influencer Insights Export Error", error })

        this.$store.dispatch("toasts/add", {
          text: error?.response?.data?.error?.message || "An error occurred. Please retry later."
        })
      } finally {
        this.isMakingRequest = false
      }
    },

    /**
     * Requeue the export with retryData received from web-sockets
     *
     * @param {Number|Null} exportId
     * @param {String|Null} exportType
     * @returns {void}
     */
    async retryExport(exportId = null, exportType = null) {
      // Compute the ID
      const modelId = exportId || this.retryData?.modelId

      // If there's no modelId
      if (!modelId) {
        this.$store.dispatch("toasts/add", {
          text: "An error occurred. Please refresh."
        })

        return false
      }

      // Put a loader
      this.isMakingRequest = true
      this.triggerId = String(Date.now())

      try {
        await axios({
          url: "/api/influencer-insight-exports/",
          method: "POST",
          data: {
            // Pass the current tab's ID to make sure only we download the file once
            clientId: getClientID(),
            triggerId: this.triggerId,
            type: exportType || this.retryData?.type || null,
            modelId
          }
        })

        // Show an alert message
        this.$store.dispatch("toasts/add", {
          text: "Your export is in queue, it might take some minutes"
        })

        // Reset the form
        this.itemsInput = defaultItemsInput()
        this.retryData = null
      } catch (error) {
        logger({ type: "Influencer Insights Export Error", error })

        this.$store.dispatch("toasts/add", {
          text: error.response.data?.error?.message || "An error occurred. Please refresh."
        })
      } finally {
        this.isMakingRequest = false
      }
    },

    /**
     * Handle the event and show option to retry the export
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the status is processing
      if (event.localData.status === constants.status.processing) {
        this.$store.dispatch("toasts/add", {
          text: "Generating your export, please wait ..."
        })
      }
      // Otherwise
      else {
        this.retryData = event.localData
      }
    },

    /**
     * Generate and download a sample XLSX sheet file
     *
     * @returns {void}
     */
    downloadSampleSheet() {
      // To disable the buttons
      this.isMakingRequest = true

      // Initiate a workbook instance
      const workbook = XLSX.utils.book_new()

      // Create the worksheet
      const worksheet = XLSX.utils.json_to_sheet([])

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet)

      // Add headers
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          "Platform",
          "Username",
          "Name",
          "Link",
          "Followers",
          "Average Views",
          "Average Likes",
          "Average Comments",
          "Engagement Rate",
          "Gender",
          "Age Group",
          "Location",
          "Interests",
          "Followers' Credibility",
          "Notable Followers",
          "Mass Followers",
          "Engagers' Credibility",
          "Notable Engagers",
          "Followers: Gender - Male",
          "Followers: Gender - Female",
          "Followers: Country 1",
          "Followers: Country 2",
          "Followers: Country 3",
          "Followers: Country 4",
          "Followers: Country 5",
          "Followers: Country 6",
          "Followers: Country 7",
          "Followers: Country 8",
          "Followers: Country 9",
          "Followers: Country 10",
          "Followers: City 1",
          "Followers: City 2",
          "Followers: City 3",
          "Followers: City 4",
          "Followers: City 5",
          "Followers: City 6",
          "Followers: City 7",
          "Followers: City 8",
          "Followers: City 9",
          "Followers: City 10",
          "Followers: Age 13-17",
          "Followers: Age 18-24",
          "Followers: Age 25-34",
          "Followers: Age 35-44",
          "Followers: Age 45-64",
          "Followers: Age 65-",
          "Likes: Gender - Male",
          "Likes: Gender - Female",
          "Likes: Country 1",
          "Likes: Country 2",
          "Likes: Country 3",
          "Likes: Country 4",
          "Likes: Country 5",
          "Likes: Country 6",
          "Likes: Country 7",
          "Likes: Country 8",
          "Likes: Country 9",
          "Likes: Country 10",
          "Likes: City 1",
          "Likes: City 2",
          "Likes: City 3",
          "Likes: City 4",
          "Likes: City 5",
          "Likes: City 6",
          "Likes: City 7",
          "Likes: City 8",
          "Likes: City 9",
          "Likes: City 10",
          "Likes: Age 13-17",
          "Likes: Age 18-24",
          "Likes: Age 25-34",
          "Likes: Age 35-44",
          "Likes: Age 45-64",
          "Likes: Age 65-",
          "Contact",
          "Influencer Brand Affinity",
          "Audience Brand Affinity",
          "Audience Interests"
        ]
      ])

      // Style with width
      worksheet["!cols"] = Array(10).fill({ wch: 16 })

      // Download this instance
      XLSX.writeFile(workbook, `Influencers Insight Sample Sheet - ${this.host.name}.xlsx`)

      // Enable the buttons again
      this.isMakingRequest = false
    }
  },

  /**
   * When the Options API mounted lifecycle hook is called
   *
   * @returns {void}
   */
  async mounted() {
    // If there's only one option, select it
    if (this.typeOptions.length === 1) {
      this.selectedType = this.typeOptions[0].value
    }

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-insight-export",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-insight-export" && event.localData.clientId === getClientID(),
      callback: this.handleMessageEvent
    })

    // Check if there's an exportId in the URL
    if (this.$route.query.exportId) {
      // Call the retryData
      await this.retryExport(Number(this.$route.query.exportId), this.$route.query.exportType || null)

      // Remove the query from the URL
      this.$router.replace({ query: {} })
    }
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus">
// to contain the entire box structure in large viewport
.page-bulk-exports
  overflow-x auto

  .options-container
    max-width 1120px
    margin 0 auto

  .results-container
    max-width 1320px
    margin 0 auto
</style>
